<template>
    <div class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="columns is-multiline">
                    <NewProduct />
                    <div class="column is-paddingless">
                        <div class="section">
                            <div class="content">
                                <div
                                    v-for="p in products"
                                    :key="p.uuid"
                                    class="box card"
                                    :style="{
                                        'border-left-style': 'solid',
                                        'border-left-width': 'medium',
                                        'border-color': p.rgbColor
                                            ? p.rgbColor
                                            : 'royalblue'
                                    }"
                                >
                                    <div class="columns is-vcentered">
                                        <div class="column is-7">
                                            <h3 class="title">
                                                {{ p.name }}
                                            </h3>
                                            <p class="subtitle">
                                                {{ p.description }}
                                            </p>
                                            <span>
                                                {{
                                                    $t(
                                                        'product.list.card.details',
                                                        {
                                                            length:
                                                                p.length / 60,
                                                            price: p.price,
                                                            vat: p.vat
                                                        }
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="column is-4">
                                            <span
                                                v-if="p.specificUsergroup"
                                                class="tag is-info is-light"
                                            >
                                                {{ p.specificUsergroup.name }}
                                            </span>
                                        </div>
                                        <div class="column is-1 has-text-right">
                                            <a @click="deleteProduct(p)">
                                                <b-icon
                                                    icon="briefcase-remove"
                                                    type="is-danger"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewProduct from './New'

export default {
    name: 'ListProduct',
    components: { NewProduct },
    data() {
        return {}
    },
    computed: {
        products() {
            return this.$store.getters['product/products']
        }
    },
    methods: {
        async deleteProduct(product) {
            await this.$store.dispatch('product/delete', product)
        }
    },
    created() {
        this.$store.dispatch('product/listAll', '')
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
