<template>
    <div class="column is-hidden-mobile is-12">
        <div class="section" style="min-width: 600px;">
            <h1 class="title">
                {{ $t('product.new.header') }}
            </h1>

            <b-field grouped>
                <b-field
                    :label="$t('product.new.name.label')"
                    label-position="inside"
                    expanded
                >
                    <b-input
                        v-model="productDetails.name"
                        :placeholder="$t('product.new.name.placeholder')"
                    />
                </b-field>

                <b-field
                    position="is-centered"
                    label=""
                    label-position="inside"
                >
                    <VSwatches
                        v-model="productDetails.color"
                        swatches="basic"
                        show-fallback
                        row-length="6"
                        shapes="circles"
                        popover-x="left"
                    />
                </b-field>
            </b-field>

            <b-field
                :label="$t('product.new.description.label')"
                label-position="inside"
            >
                <b-input
                    v-model="productDetails.description"
                    :placeholder="$t('product.new.description.placeholder')"
                />
            </b-field>

            <b-field
                :label="$t('product.new.length.label')"
                label-position="inside"
                grouped
            >
                <b-field
                    :label="$t('product.new.length.label')"
                    label-position="inside"
                >
                    <b-input
                        v-model.number="productDetails.length"
                        :placeholder="$t('product.new.length.placeholder')"
                    />
                </b-field>

                <b-field
                    :label="$t('product.new.price.label')"
                    label-position="inside"
                    expanded
                >
                    <b-field>
                        <b-input
                            v-model="productDetails.price"
                            :placeholder="$t('product.new.price.placeholder')"
                            expanded
                        />
                        <b-select v-model.number="productDetails.vat">
                            <option value="25">
                                25%
                            </option>
                            <option value="0">
                                {{ $t('product.new.noVat') }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>
            </b-field>

            <b-field
                :label="$t('product.new.specificUsergroup.label')"
                label-position="inside"
            >
                <b-taginput
                    v-model="chosenCustomers"
                    :data="usergroups"
                    autocomplete
                    :open-on-focus="true"
                    field="name"
                    icon="label"
                    :placeholder="
                        $t('product.new.specificUsergroup.placeholder')
                    "
                    @typing="getUsergroups"
                >
                    <template slot="empty">
                        {{ $t('product.new.specificUsergroup.noMatch') }}
                    </template>
                </b-taginput>
            </b-field>
            <b-button @click="submitProduct">
                {{ $t('product.new.buttons.create') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
    name: 'NewProduct',
    components: { VSwatches },
    data() {
        return {
            filteredCustomers: this.usergroups,
            chosenCustomers: [],
            productDetails: {
                name: null,
                description: null,
                length: null,
                price: null,
                vat: 25,
                color: null,
                specificUsergroup: null
            },
            formReset: null
        }
    },
    computed: {
        customerTargets() {
            var targets = []
            if (this.chosenCustomers) {
                targets = this.chosenCustomers.reduce((base, customer) => {
                    base.push(customer['@id'])

                    return base
                }, [])
            }
            return targets
        },
        isLoading() {
            return this.$store.getters['usergroup/isLoading']
        },
        hasError() {
            return this.$store.getters['usergroup/hasError']
        },
        error() {
            return this.$store.getters['usergroup/error']
        },
        hasUsergroups() {
            return this.$store.getters['usergroup/hasUsergroups']
        },
        usergroups() {
            return this.$store.getters['usergroup/usergroups']
        }
    },
    methods: {
        getUsergroups(text) {
            this.filteredCustomers = this.usergroups.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                )
            })
        },
        async submitProduct() {
            if (this.chosenCustomers.length > 0) {
                for (var i = 0; i < this.customerTargets.length; i++) {
                    // eslint-disable-next-line prettier/prettier
                    this.productDetails.specificUsergroup = this.customerTargets[i]
                    await this.$store.dispatch(
                        'product/create',
                        this.productDetails
                    )
                }
            } else {
                await this.$store.dispatch(
                    'product/create',
                    this.productDetails
                )
            }
            this.resetForm()
        },
        resetForm() {
            this.productDetails = { ...this.formReset }
            this.chosenCustomers = []
        }
    },
    created() {
        this.resetForm = { ...this.productDetails }
        this.$store.dispatch('usergroup/allCustomers')
    }
}
</script>
